var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "mb": ['12px', '16px'],
      "is-invalid": _vm.invalid
    }
  }, [_vm.label ? _c('c-form-label', {
    attrs: {
      "for": _vm.label,
      "font-size": ['14px', '16px'],
      "color": _vm.invalid ? '#D32737' : '#008C81',
      "font-family": "Roboto",
      "ml": "12px",
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _vm._t("default", function () {
    return [_c('c-flex', {
      attrs: {
        "w": "100%",
        "pos": "relative",
        "align-items": "center"
      }
    }, [_vm.iconLeft ? _c('c-box', {
      attrs: {
        "pos": "absolute",
        "left": "0px",
        "z-index": "2",
        "w": "3.5rem"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": _vm.iconLeft,
        "height": "25",
        "width": "100%",
        "fill": _vm.invalid ? '#D32737' : '#008C81'
      }
    })], 1) : _vm._e(), _c('c-input', _vm._g(_vm._b({
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          _disabled: {
            opacity: 1,
            background: '#f2f2f2'
          }
        },
        expression: "{\n          _disabled: {\n            opacity: 1,\n            background: '#f2f2f2'\n          }\n        }"
      }],
      attrs: {
        "h": ['48px', '62px'],
        "border-type": "solid",
        "border-width": "1px",
        "border-color": _vm.invalid ? '#D32737' : '#008C81',
        "border-radius": "8px",
        "pl": _vm.iconLeft ? '3.5rem' : '1rem',
        "pr": _vm.iconRight ? '3.5rem' : '1rem',
        "font-family": "Roboto",
        "font-size": ['14px', '18px'],
        "font-weight": "500",
        "readonly": _vm.isReadOnly,
        "color": _vm.invalid ? '#D32737' : '#008C81'
      }
    }, 'c-input', _vm.$attrs, false), _vm.$listeners)), _vm.iconRight ? _c('c-box', {
      attrs: {
        "pos": "absolute",
        "left": "0px",
        "z-index": "2",
        "w": "3.5rem"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": _vm.iconRight,
        "height": "25",
        "width": "100%",
        "fill": _vm.invalid ? '#D32737' : '#008C81'
      }
    })], 1) : _vm._e()], 1)];
  }), _vm.invalid && _vm.errorMessage ? _c('c-form-helper-text', {
    attrs: {
      "id": _vm.label,
      "color": "#D32737",
      "ml": "4px",
      "mt": "4px",
      "font-weight": "500",
      "fontfamily": "Roboto",
      "font-size": "12px",
      "d": "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-alert-circle.svg'),
      "alt": "icon notification",
      "w": "15px",
      "h": "15px",
      "mx": "6px"
    }
  }), _vm._v(" " + _vm._s(_vm.errorMessage) + " ")], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }