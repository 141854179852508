<template>
  <c-box flex-grow="1">
    <form
      v-chakra
      mt="1rem"
    >
      <form-field
        v-model="value.firstName"
        label="Nama Depan"
        placeholder="Nama Depan"
        type="text"
        :icon-left="require('@/assets/icons/icon-assignment.svg')"
        :is-read-only="!isEditable"
        :invalid="!isEditable ? false : (errors.firstName ? true : false)"
        :error-message="!isEditable ? '' : errors.firstName"
      />
      <form-field
        v-model="value.lastName"
        label="Nama Belakang"
        placeholder="Nama Belakang"
        type="text"
        :icon-left="require('@/assets/icons/icon-assignment.svg')"
        :is-read-only="!isEditable"
        :invalid="!isEditable ? false : (errors.lastName ? true : false)"
        :error-message="!isEditable ? '' : errors.lastName"
      />
      <c-form-control
        :mb="['12px', '16px']"
        :is-invalid="!isEditable ? false : errors.gender"
      >
        <c-form-label  
          for="gender" 
          :font-size="['14px', '16px']" 
          :color="(isEditable && errors.gender ? '#D32737' : '#008C81')" 
          font-family="Roboto" 
          ml="12px" 
          font-weight="400"
        >
          Jenis Kelamin
        </c-form-label>
        <c-flex
          w="100%"
          pos="relative"
          align-items="center"
        >
          <c-box
            pos="absolute"
            left="0px"
            z-index="2"
            w="3.5rem"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-gender.svg')"
              height="25"
              width="100%"
              :fill="(isEditable && errors.gender ? '#D32737' : '#008C81')"
            />
          </c-box>
          <c-select
            v-model="value.gender"
            placeholder="Pilih Jenis Kelamin"
            :h="['48px', '62px']"
            border-radius="8px"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            font-weight="500"
            :border-color="(isEditable && errors.gender ? '#D32737' : '#008C81')"
            :color="(isEditable && errors.gender ? '#D32737' : '#008C81')"
            :disabled="!isEditable"
          >
            <option
              v-for="item in genders"
              :key="item.name"
              :value="item.name"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-flex>
        <c-form-helper-text
          v-if="isEditable && errors.gender"
          id="gender"
          color="#D32737"
          ml="4px"
          mt="4px"
          font-weight="500"
          fontfamily="Roboto"
          font-size="12px"
          d="flex"
          flex-direction="row"
          align-items="center"
        >
          <c-image
            :src="require('@/assets/icon-alert-circle.svg')"
            alt="icon notification"
            w="15px"
            h="15px"
            mx="6px"
          />
          {{ errors.gender }}
        </c-form-helper-text>
      </c-form-control>
      <form-field
        label="Tanggal Lahir"
        placeholder="DD-MM-YYYY"
        :read-only="!isEditable"
        :invalid="!isEditable ? false : (errors.birthDate ? true : false)"
        :error-message="!isEditable ? '' : errors.birthDate"
      >
        <v-date-picker
          v-model="value.birthDate"
          :masks="masks"
          :max-date="new Date()"
          :model-config="modelConfig"
          mode="date"
        >
          <template #default="{ inputValue, togglePopover }">
            <c-flex
              w="100%"
              pos="relative"
              align-items="center"
            >
              <c-box
                pos="absolute"
                left="0px"
                z-index="2"
                w="3.5rem"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-date.svg')"
                  height="25"
                  width="100%"
                  :fill="(isEditable && errors.birthDate ? '#D32737' : '#008C81')"
                />
              </c-box>
              <c-input
                :value="inputValue"
                :h="['48px', '62px']"
                border-type="solid"
                border-width="1px"
                :border-color="(isEditable && errors.birthDate ? '#D32737' : '#008C81')"
                border-radius="8px"
                pl="3.5rem"
                font-family="Roboto"
                :font-size="['14px', '18px']"
                font-weight="500"
                :color="(isEditable && errors.birthDate ? '#D32737' : '#008C81')"
                readonly
                @focus="() => {
                  if (isEditable) {
                    togglePopover()
                  }
                }"
              />
            </c-flex>
          </template>
        </v-date-picker>
      </form-field>
      <c-form-control
        v-if="countries.length > 0"
        :mb="['12px', '16px']"
        :is-invalid="!isEditable ? false : errors.country"
      >
        <c-form-label  
          for="country" 
          :font-size="['14px', '16px']" 
          :color="(isEditable && errors.country ? '#D32737' : '#008C81')" 
          font-family="Roboto" 
          ml="12px" 
          font-weight="400"
        >
          Negara
        </c-form-label>
        <c-flex
          w="100%"
          pos="relative"
          align-items="center"
        >
          <c-box
            pos="absolute"
            left="0px"
            z-index="2"
            w="3.5rem"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-place.svg')"
              height="25"
              width="100%"
              :fill="(isEditable && errors.country ? '#D32737' : '#008C81')"
            />
          </c-box>
          <c-select
            v-model="value.country"
            v-chakra="{
              'select': {
                opacity: '1 !important',
              },
            }"
            placeholder="Pilih Negara"
            :h="['48px', '62px']"
            border-radius="8px"
            font-family="Roboto"
            :font-size="['14px', '18px']"
            font-weight="500"
            :border-color="(isEditable && errors.country ? '#D32737' : '#008C81')"
            :color="(isEditable && errors.country ? '#D32737' : '#008C81')"
            :disabled="!isEditable"
          >
            <option
              v-for="item in countries"
              :key="item.name"
              :value="item.name"
            >
              {{ item.label }}
            </option>
          </c-select>
        </c-flex>
        <c-form-helper-text
          v-if="isEditable && errors.country"
          id="country"
          color="#D32737"
          ml="4px"
          mt="4px"
          font-weight="500"
          fontfamily="Roboto"
          font-size="12px"
          d="flex"
          flex-direction="row"
          align-items="center"
        >
          <c-image
            :src="require('@/assets/icon-alert-circle.svg')"
            alt="icon notification"
            w="15px"
            h="15px"
            mx="6px"
          />
          {{ errors.country }}
        </c-form-helper-text>
      </c-form-control>
      <form-field
        v-model="value.phone"
        label="Nomor Telepon"
        placeholder="Nomor Telepon"
        type="number"
        :icon-left="require('@/assets/icons/icon-telephone.svg')"
        :is-read-only="!isEditable"
        min="0"
        inputmode="numeric"
        :invalid="!isEditable ? false : (errors.phone ? true : false)"
        :error-message="!isEditable ? '' : errors.phone"
      />
      <form-field
        v-model="value.email"
        label="Email"
        placeholder="Email"
        type="email"
        :icon-left="require('@/assets/icons/icon-email.svg')"
        :is-read-only="!isEditable"
        :disabled="true"
        :invalid="!isEditable ? false : (errors.email ? true : false)"
        :error-message="!isEditable ? '' : errors.email"
      />

      <c-button
        v-if="!isEditable"
        variant="solid"
        variant-color="primary"
        border-radius="60px"
        w="100%"
        :h="['50px', '62px']"
        :font-size="['14px', '18px']"
        mt="20px"
        font-family="Roboto"
        @click="$emit('edit')"
      >
        Edit
      </c-button>
      
      <c-flex
        v-else
        pt="20px"
        justify-content="space-between"
      >
        <c-button
          :d="['none', 'block']"
          bg="#C4C4C4"
          border-radius="60px"
          w="50%"
          :h="['50px', '62px']"
          :font-size="['14px', '18px']"
          mr="20px"
          color="#FFF"
          font-family="Roboto"
          @click="$emit('cancel')"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          :w="['100%', '50%']"
          :h="['50px', '62px']"
          :font-size="['14px', '18px']"
          font-family="Roboto"
          :is-disabled="isDisabledSubmit"
          @click="$emit('submit')"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
  </c-box>
</template>

<script>
import FormField from '../form-field.vue'
import { GENDERS } from '@/constants/generals'

export default {
  name: 'ProfileDetailForm',
  components: {
    FormField,  
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    states: {
      type: Array,
      required: true,
    },
    cities: {
      type: Array,
      required: true,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    photoChanging: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      genders: GENDERS,
      isChanged: false,
      masks: {
        input: 'DD-MM-YYYY',
        data: 'YYYY-MM-DD',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
        timeAdjust: '00:00:00',
      },
    }
  },
  computed: {
    isDisabledSubmit() {
      return !this.value.firstName || !this.value.lastName || !this.value.gender || !this.value.birthDate || !this.value.phone || !this.value.country || !this.isChanged
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          if (this.isEditable) {
            this.isChanged = true
          }
          this.value = val
        } else {
          this.value = {
            address: null,
            birthDate: null,
            birthPlace: null,
            city: null,
            country: null,
            email: null,
            firstName: null,
            gender: null,
            id: null,
            lastName: null,
            phone: null,
            photoUrl: null,
            province: null,
            zipCode: null,
          }
        }
      },
    },
    isEditable: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.isChanged = false
        }
      },
    },
    photoChanging: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isChanged = true
        } else {
          this.isChanged = false
        }
      }, 
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CSelectInput"] {
  padding-left: 3.5rem !important;
  padding-right: 1rem !important;
  opacity: 1 !important;
  border-style: solid;
  border-width: 1px;
}
::v-deep [data-chakra-component="CSelectInput"]:disabled {
  opacity: 1 !important;
  /* background: #F2F2F2; */
  /* border-color: #888888 !important; */
}
</style>