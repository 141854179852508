<template>
  <c-form-control
    :mb="['12px', '16px']"
    :is-invalid="invalid"
  >
    <c-form-label
      v-if="label"
      :for="label"
      :font-size="['14px', '16px']"
      :color="invalid ? '#D32737' : '#008C81'"
      font-family="Roboto"
      ml="12px"
      font-weight="400"
    >
      {{ label }}
    </c-form-label>
    <slot>
      <c-flex
        w="100%"
        pos="relative"
        align-items="center"
      >
        <c-box
          v-if="iconLeft"
          pos="absolute"
          left="0px"
          z-index="2"
          w="3.5rem"
        >
          <inline-svg
            :src="iconLeft"
            height="25"
            width="100%"
            :fill="invalid ? '#D32737' : '#008C81'"
          />
        </c-box>
        <c-input
          v-chakra="{
            _disabled: {
              opacity: 1,
              background: '#f2f2f2'
            }
          }"
          v-bind="$attrs"
          :h="['48px', '62px']"
          border-type="solid"
          border-width="1px"
          :border-color="(invalid ? '#D32737' : '#008C81')"
          border-radius="8px"
          :pl="iconLeft ? '3.5rem' : '1rem'"
          :pr="iconRight ? '3.5rem' : '1rem'"
          font-family="Roboto"
          :font-size="['14px', '18px']"
          font-weight="500"
          :readonly="isReadOnly"
          :color="invalid ? '#D32737' : '#008C81'"
          v-on="$listeners"
        />
        <c-box
          v-if="iconRight"
          pos="absolute"
          left="0px"
          z-index="2"
          w="3.5rem"
        >
          <inline-svg
            :src="iconRight"
            height="25"
            width="100%"
            :fill="invalid ? '#D32737' : '#008C81'"
          />
        </c-box>
      </c-flex>
    </slot>
    <c-form-helper-text
      v-if="invalid && errorMessage"
      :id="label"
      color="#D32737"
      ml="4px"
      mt="4px"
      font-weight="500"
      fontfamily="Roboto"
      font-size="12px"
      d="flex"
      flex-direction="row"
      align-items="center"
    >
      <c-image
        :src="require('@/assets/icon-alert-circle.svg')"
        alt="icon notification"
        w="15px"
        h="15px"
        mx="6px"
      />
      {{ errorMessage }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
