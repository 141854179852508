<template>
  <c-box flex-grow="1">
    <h1
      v-chakra
      :d="['none', 'block']"
      font-weight="bold"
      font-size="18px"
      color="#111111"
      mb="30px"
    >
      Edit Profile
    </h1>
    <form
      v-chakra
      :mt="['70px', 0]"
    >
      <c-form-control
        :is-invalid="$v.value.firstName.$invalid"
        is-required
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Depan
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="value.firstName"
            type="text"
            placeholder="Masukkan Nama Depan"
            height="62px"
          />
        </c-input-group>
      </c-form-control>
      <c-form-control mb="25px">
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Belakang
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="value.lastName"
            type="text"
            placeholder="Masukkan Nama Belakang"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        :is-invalid="$v.value.email.$invalid"
        is-required
        mb="80px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Email
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="value.email"
            type="email"
            error-border-color="red.300"
            placeholder="Masukkan Email"
            height="62px"
            variant="filled"
            disabled
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="oldPassword && $v.oldPassword.$invalid"
        mt="80px"
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Password Lama
        </c-form-label>
        <c-input-group>
          <c-input
            v-model="oldPassword"
            :type="shownPassword.includes('oldPassword') ? `text` : `password`"
            placeholder="Masukkan Password Lama"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('oldPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
      </c-form-control>
      <c-form-control
        is-required
        :is-invalid="newPassword && $v.newPassword.$invalid"
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Password Baru
        </c-form-label>
        <c-input-group>
          <c-input
            v-model="newPassword"
            :type="shownPassword.includes('newPassword') ? `text` : `password`"
            placeholder="Masukkan Password Baru"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPassword')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.minLength"
          color="red.500"
        >
          Password minimal 8 karakter
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasNumber"
          color="red.500"
        >
          Password harus mengandung angka
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasLowercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kecil
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasUppercaseLetter"
          color="red.500"
        >
          Password harus mengandung huruf kapital
        </c-form-helper-text>
        <c-form-helper-text
          v-if="newPassword && !$v.newPassword.hasSpecialCharacter"
          color="red.500"
        >
          Password harus mengandung karakter spesial @$!%()*?&
        </c-form-helper-text>
      </c-form-control>

      <c-form-control
        is-required
        :is-invalid="
          newPasswordConfirmation && $v.newPasswordConfirmation.$invalid
        "
        mb="25px"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Konfirmasi Password Baru
        </c-form-label>
        <c-input-group>
          <c-input
            v-model="newPasswordConfirmation"
            :type="
              shownPassword.includes('newPasswordConfirmation')
                ? `text`
                : `password`
            "
            placeholder="Ulangi Password Baru"
            h="62px"
            tabindex="1"
          />
          <c-input-right-element>
            <c-button
              variant="ghost"
              p="0"
              m="0"
              top="50%"
              right="10px"
              transform="translateY(-20%)"
              @click="togglePasswordVisibility('newPasswordConfirmation')"
            >
              <c-image
                :src="require('@/assets/icon-show-password.svg')"
                alt="toggle password visibility"
              />
            </c-button>
          </c-input-right-element>
        </c-input-group>
        <!--Show error here-->
        <c-form-helper-text
          v-if="!$v.newPasswordConfirmation.sameAs"
          color="red.500"
        >
          Password baru tidak sama
        </c-form-helper-text>
      </c-form-control>

      <c-flex
        mt="70px"
        justify-content="space-between"
      >
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="$router.push('/')"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :is-disabled="isDisabledSubmit"
          @click="onOpenConfirm"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>
    
    <ModalConfirm 
      title="Apa kamu yakin ingin mengubah data?"
      :is-open="isConfirm"
      :is-loading="isSubmitting"
      @close="onCloseConfirm"
      @submit="onSubmit"
    />

    <ModalError
      :is-open="isOpenError"
      :image="require('@/assets/images/illustration-6-raw.png')"
      title="Terdapat data yang salah"
      :note="errorNote"
      button-text="Kembali"
      :button-icon="false"
      :button-action="() => onCloseError()"
      @close="onCloseError"
    />
  </c-box>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import ModalConfirm from '@/components/widgets/modal-confirm'
import ModalError from '@/components/widgets/modal-error.vue'

export default {
  name: 'ProfileDetailForm',
  components: {
    ModalConfirm,
    ModalError,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
      shownPassword: [],
      isSucceeded: false,
      isConfirm: false,
      isSubmitting: false,
      isOpenError: false,
      errorNote: '',
    }
  },
  computed: {
    ...mapGetters({ axios: 'axios' }),
    isDisabledSubmit() {
      if (this.$v.value.firstName.$invalid) {
        return true
      }
      return false
    },
  },
  watch: {
    immediate: true,
    value: {
      handler(val) {
        if (val) {
          this.value = val
        } else {
          this.value = {
            firstName: null,
            lastName: null,
            email: null,
            photoUrl: null,
          }
        }
      },
    },
  },
  validations: {
    value: {
      firstName: { required },
      email: { required, email },
    },
    oldPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8),
      hasNumber(value) {
        return /\d/.test(value)
      },
      hasLowercaseLetter(value) {
        return /[a-z]/.test(value)
      },
      hasUppercaseLetter(value) {
        return /[A-Z]/.test(value)
      },
      hasSpecialCharacter(value) {
        let reSpecialCharacter = /[@$!%()*?&]/
        return reSpecialCharacter.test(value)
      },
    },
    newPasswordConfirmation: { required, sameAs: sameAs('newPassword') },
  },
  methods: {
    togglePasswordVisibility(name) {
      if (this.shownPassword.includes(name)) {
        this.shownPassword = this.shownPassword.filter((it) => it !== name)
      } else {
        this.shownPassword.push(name)
      }
    },
    onOpenConfirm() {
      this.isConfirm = true
    },
    onCloseConfirm() {
      this.isConfirm = false
      this.isSubmitting = false
    },
    onOpenError() {
      this.isOpenError = true
    },
    onCloseError() {
      this.isOpenError = false
    },
    async onSubmit() {
      this.isSubmitting = true
      this.$v.$touch()
      if (
        this.oldPassword && 
        this.newPassword && 
        this.newPasswordConfirmation &&
        (this.$v.oldPassword.$invalid ||
        this.$v.newPassword.$invalid ||
        this.$v.newPasswordConfirmation.$invalid)
      ) {
        this.onCloseConfirm()
        this.errorNote = 'Silahkan cek kembali isian form password'
        this.isOpenError = true
        return
      }

      if (
        this.oldPassword &&
        this.newPassword &&
        this.newPasswordConfirmation
      ) {
        try {
          await this.axios
            .put('/v1/users/update-password', {
              currentPassword: this.oldPassword,
              newPassword: this.newPassword,
            })
          
          this.$v.$reset()
          this.oldPassword = null
          this.newPassword = null
          this.newPasswordConfirmation = null
          setTimeout(() => {
            this.$router.replace({ name: 'auth', query: { tab: 'login' } })
          }, 2000)
        } catch (err) {
          this.errorNote = err.response.data.message == 'Password is wrong' ? 'Password lama anda salah, silahkan cek kembali.' : err.response.data.message
          this.isOpenError = true
          this.onCloseConfirm()
          return
        }
      }

      if (!this.$v.value.firstName.$invalid) {
        this.$emit('submit')
      }
      setTimeout(() => {
        this.onCloseConfirm()
      }, 2500)
    },
  },
}
</script>
