var render = function () {
  var _vm$item$str, _vm$item$email, _vm$item$firstName, _vm$item$lastName, _vm$item$languages, _vm$item$clientAgeHan;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '2rem 4rem'],
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "28px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Ahli Gizi ")])], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "20px",
      "line-height": "27px",
      "color": "#008C81"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px",
      "color": "#888"
    }
  }), _c('c-box', {
    attrs: {
      "mb": "43px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "6px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Foto Ahli Gizi ")]), _c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.item.photoUrl),
      "w": "150px",
      "h": "150px",
      "alt": "avatar",
      "border-radius": "50%",
      "object-fit": "cover"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nomor STR ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$str = _vm.item.str) !== null && _vm$item$str !== void 0 ? _vm$item$str : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Email ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$email = _vm.item.email) !== null && _vm$item$email !== void 0 ? _vm$item$email : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$firstName = _vm.item.firstName) !== null && _vm$item$firstName !== void 0 ? _vm$item$firstName : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$lastName = _vm.item.lastName) !== null && _vm$item$lastName !== void 0 ? _vm$item$lastName : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Gelar ")]), _vm._l(_vm.item.education, function (edu, index) {
    var _edu$title;

    return _c('c-text', {
      key: 'gelar' + index,
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s((_edu$title = edu.title) !== null && _edu$title !== void 0 ? _edu$title : '-') + " ")]);
  })], 2), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pendidikan ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.education, function (edu, index) {
    return _c('c-list-item', {
      key: 'edu' + index,
      attrs: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(edu.level) + " " + _vm._s(edu.university === "0" ? edu.otherUniversity : _vm.getUniversityName(edu.university)) + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.education),
      expression: "isDataEmpty(item.education)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.specialization ? Array.isArray(_vm.item.specialization) ? _vm.item.specialization.join(', ') : _vm.item.specialization : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Bahasa yang dikuasai ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$languages = _vm.item.languages) !== null && _vm$item$languages !== void 0 ? _vm$item$languages : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s((_vm$item$clientAgeHan = _vm.item.clientAgeHandled) !== null && _vm$item$clientAgeHan !== void 0 ? _vm$item$clientAgeHan : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Masalah yang dikuasai ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.problemHandled ? Array.isArray(_vm.item.problemHandled) ? _vm.item.problemHandled.join(', ') : _vm.item.problemHandled : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jam Layanan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.serviceHour ? "".concat(_vm.item.serviceHour, " WIB") : '-') + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jam Aktif Chat ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.activeHour, function (active, index) {
    return _c('c-list-item', {
      key: 'active' + index
    }, [_vm._v(" " + _vm._s(active.startHour) + " - " + _vm._s(active.endHour) + " WIB ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.activeHour),
      expression: "isDataEmpty(item.activeHour)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Jadwal Layanan ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.item.serviceTime, function (service, index) {
    return _c('c-list-item', {
      key: 'service' + index
    }, [_vm._v(" " + _vm._s(service.title) + ": " + _vm._s(service.value ? service.value : '-') + " ")]);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.serviceTime),
      expression: "isDataEmpty(item.serviceTime)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pengalaman Bekerja ")]), _c('c-list', {
    attrs: {
      "style-type": "disc",
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "600",
      "font-family": "Roboto"
    }
  }, [_vm._l(_vm.workExperiences, function (work, index) {
    return _c('c-list-item', {
      key: 'work' + index
    }, [_c('c-box', {
      attrs: {
        "display": "inline-table"
      }
    }, [_c('c-text', [_vm._v(" " + _vm._s(work.companyName) + " ")]), _c('c-box', {
      attrs: {
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(work.position) + " (" + _vm._s(work.startYear) + " - " + _vm._s(work.endYear) + ") ")])], 1)], 1);
  }), _c('c-list-item', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDataEmpty(_vm.item.workExperiences),
      expression: "isDataEmpty(item.workExperiences)"
    }]
  }, [_vm._v(" - ")])], 2)], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Kuota ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.quota) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Rating ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_c('StarRating', {
    attrs: {
      "increment": 0.5,
      "read-only": true,
      "star-size": 26,
      "text-class": "hidden"
    },
    model: {
      value: _vm.item.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "rating", $$v);
      },
      expression: "item.rating"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }