<template>
  <c-box
    position="relative"
    mx="auto"
    :mb="['0', '16px']"
    :p="['1rem', '2rem 4rem']"
    width="100%"
  >
    <c-flex
      justify="space-between"
      align="center"
      mb="30px"
    >
      <c-heading
        as="h3"
        font-family="Roboto"
        font-weight="bold"
        font-size="28px"
        line-height="36px"
      >
        Detail Ahli Gizi
      </c-heading>
    </c-flex>

    <c-heading
      as="h4"
      font-family="Roboto"
      font-weight="bold"
      font-size="20px"
      line-height="27px"
      color="#008C81"
    >
      Data Pribadi
    </c-heading>
    <c-divider
      mt="10px"
      mb="22px"
      color="#888"
    />

    <c-box mb="43px">
      <c-form-control mb="30px">
        <c-text
          mb="6px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Foto Ahli Gizi
        </c-text>
        <c-image
          :src="getPhotoUser(item.photoUrl)"
          w="150px"
          h="150px"
          alt="avatar"
          border-radius="50%"
          object-fit="cover"
        />
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nomor STR
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.str ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Email
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="18px"
          line-height="27px"
        >
          {{ item.email ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Depan
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.firstName ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Belakang
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.lastName ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Gelar
        </c-text>
        <c-text
          v-for="(edu, index) in item.education"
          :key="'gelar' + index"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ edu.title ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Pendidikan
        </c-text>
        <c-list
          style-type="disc"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
          font-family="Roboto"
        >
          <c-list-item
            v-for="(edu, index) in item.education"
            :key="'edu' + index"
            text-transform="capitalize"
          >
            {{ edu.level }}
            {{
              edu.university === "0"
                ? edu.otherUniversity
                : getUniversityName(edu.university)
            }}
          </c-list-item>
          <c-list-item v-show="isDataEmpty(item.education)">
            -
          </c-list-item>
        </c-list>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Spesialisasi / Keahlian
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.specialization ? (Array.isArray(item.specialization) ? item.specialization.join(', ') : item.specialization) : '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Bahasa yang dikuasai
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.languages ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Usia Klien
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.clientAgeHandled ?? '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Masalah yang dikuasai
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.problemHandled ? (Array.isArray(item.problemHandled) ? item.problemHandled.join(', ') : item.problemHandled) : '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Jam Layanan
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.serviceHour ? `${item.serviceHour} WIB` : '-' }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Jam Aktif Chat
        </c-text>
        <c-list
          style-type="disc"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
          font-family="Roboto"
        >
          <c-list-item
            v-for="(active, index) in item.activeHour"
            :key="'active' + index"
          >
            {{ active.startHour }} - {{ active.endHour }} WIB
          </c-list-item>
          <c-list-item v-show="isDataEmpty(item.activeHour)">
            -
          </c-list-item>
        </c-list>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Jadwal Layanan
        </c-text>
        <c-list
          style-type="disc"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
          font-family="Roboto"
        >
          <c-list-item
            v-for="(service, index) in item.serviceTime"
            :key="'service'+ index"
          >
            {{ service.title }}: {{ service.value ? service.value : '-' }}
          </c-list-item>
          <c-list-item v-show="isDataEmpty(item.serviceTime)">
            -
          </c-list-item>
        </c-list>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Pengalaman Bekerja
        </c-text>
        <c-list
          style-type="disc"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="600"
          font-family="Roboto"
        >
          <c-list-item
            v-for="(work, index) in workExperiences"
            :key="'work' + index"
          >
            <c-box display="inline-table">
              <c-text>
                {{ work.companyName }}
              </c-text>
              <c-box font-weight="400">
                {{ work.position }} ({{ work.startYear }} - {{ work.endYear }})
              </c-box>
            </c-box>
          </c-list-item>
          <c-list-item v-show="isDataEmpty(item.workExperiences)">
            -
          </c-list-item>
        </c-list>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Kuota
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          font-weight="500"
        >
          {{ item.quota }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Rating
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          <StarRating
            v-model="item.rating"
            :increment="0.5"
            :read-only="true"
            :star-size="26"
            text-class="hidden"
          />
        </c-text>
      </c-form-control>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import StarRating from 'vue-star-rating'
import generalMixin from '@/utils/general-mixins'
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'ManagementNutritionistsDetail',
  components: {
    StarRating,
  },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
      isLoadingClients: false,
      isHasClient: false,
    }
  },
  computed: {
    ...mapGetters({
      universities: 'general/universities',
      item: 'profile/dataProfile',
    }),
    workExperiences() {
      if (this.item?.workExperiences) {
        return _.reverse(this.item.workExperiences)
      }
      return []
    },
  },
  async mounted() {
    this.loadUniversities()
    this.getProfile()
  },
  methods: {
    isDataEmpty,
    ...mapActions({
      getProfile: 'profile/getProfile',
      loadUniversities: 'general/loadUniversities',
    }),
    getUniversityName(id) {
      if (this.universities.length === 0) return ''
      const data = this.universities.find((dt) => dt.id.toString() === id)
      if (!_.isEmpty(data)) {
        return data.name
      }
      return ''
    },
  },
}
</script>
