var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "min-h": ['calc(100vh - 62px)', 'auto'],
      "bg": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumb
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "space-between",
      "flex-grow": "1",
      "width": _vm.user.role === 'nutritionist' ? '100%' : ['100%', '540px'],
      "mx": "auto",
      "bg": ['#FFF', 'transparent'],
      "border-radius": "16px",
      "p": ['1rem', '0']
    }
  }, [_vm.user.role !== 'nutritionist' ? _c('c-text', {
    attrs: {
      "d": ['none', 'block'],
      "font-family": "Roboto",
      "font-size": "28px",
      "color": "#111",
      "font-weight": "700",
      "mb": "8px"
    }
  }, [_vm._v(" Data " + _vm._s(_vm.user.role === 'client' ? 'Klien' : _vm.user.role === 'nutritionist' ? 'Ahli Gizi' : 'Admin') + " ")]) : _vm._e(), _vm.user.role !== 'nutritionist' ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "ml": !_vm.isClient && !_vm.isNutri ? [null, '164px'] : [null, '60px']
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.tempPhotoUrl ? _vm.tempPhotoUrl : _vm.profile.photoUrl),
      "alt": _vm.profile.firstName,
      "w": ['150px', '200px'],
      "h": ['150px', '200px'],
      "mx": "auto",
      "border-radius": "50%"
    }
  }), _c('input', {
    ref: "inputAvatar",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": "image/png, image/jpg, image/jpeg"
    },
    on: {
      "change": _vm.onFileChange
    }
  }), _vm.isNutri || _vm.isEditable ? _c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "blue",
      "border-radius": "25px",
      "flex-direction": "row",
      "align-items": "center",
      "h": "42px",
      "mx": "auto",
      "mt": "8px",
      "is-loading": _vm.isUploading,
      "loading-text": "Uploading",
      "font-family": "Roboto",
      "font-size": ['14px', '16px']
    },
    on: {
      "click": function click($event) {
        _vm.$refs.inputAvatar.value = '';

        _vm.$refs.inputAvatar.click();
      }
    }
  }, [_vm._v(" Ganti foto "), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-edit-blue.svg'),
      "width": ['15px', '22px'],
      "height": ['15px', '22px'],
      "ml": "8px"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.isSuperAdmin || _vm.isAdmin ? _c('ProfileFormDetail', {
    on: {
      "submit": _vm.onSubmit
    },
    model: {
      value: _vm.profile,
      callback: function callback($$v) {
        _vm.profile = $$v;
      },
      expression: "profile"
    }
  }) : _vm._e(), _vm.isNutri ? _c('ProfileNutriFormDetail', {
    on: {
      "submit": _vm.onOpenConfirm
    },
    model: {
      value: _vm.profile,
      callback: function callback($$v) {
        _vm.profile = $$v;
      },
      expression: "profile"
    }
  }) : _vm._e(), _vm.isClient ? _c('ProfileClientFormDetail', {
    attrs: {
      "countries": _vm.countries,
      "states": _vm.states,
      "cities": _vm.cities,
      "is-editable": _vm.isEditable,
      "errors": _vm.errors,
      "photo-changing": !!_vm.tempPhotoUrl
    },
    on: {
      "submit": _vm.onOpenConfirm,
      "edit": _vm.onEdit,
      "cancel": _vm.onCancel
    },
    model: {
      value: _vm.profile,
      callback: function callback($$v) {
        _vm.profile = $$v;
      },
      expression: "profile"
    }
  }) : _vm._e(), _c('ModalCropper', {
    attrs: {
      "is-open": _vm.isCropper,
      "image": _vm.cropper.imageUrl,
      "is-loading": _vm.isUploading,
      "file-name": _vm.cropper.file ? _vm.cropper.file.name : 'file.jpg'
    },
    on: {
      "close": _vm.onCloseCropper,
      "submit": _vm.onCropAvatar
    }
  }), _c('ModalConfirm', {
    attrs: {
      "title": "Apa kamu yakin ingin mengubah data?",
      "is-open": _vm.isConfirm,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmit
    }
  }), _c('ModalSuccess', {
    attrs: {
      "title": "Data berhasil diubah!",
      "note": "",
      "image": require('@/assets/paid.png'),
      "is-open": _vm.isSucceeded,
      "button-text": "Oke"
    },
    on: {
      "close": _vm.handleCloseSuccessModal
    }
  }), _c('ModalSuccess', {
    attrs: {
      "title": "Terdapat data yang salah di pembaharuanmu",
      "note": "",
      "image": require('@/assets/images/illustration-6-raw.png'),
      "is-open": _vm.isError,
      "button-text": "Oke"
    },
    on: {
      "close": _vm.handleCloseErrorModal
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }