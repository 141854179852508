var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('h1', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "d": ['none', 'block'],
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111111",
      "mb": "30px"
    }
  }, [_vm._v(" Edit Profile ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": ['70px', 0]
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.value.firstName.$invalid,
      "is-required": "",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Nama Depan",
      "height": "62px"
    },
    model: {
      value: _vm.value.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "firstName", $$v);
      },
      expression: "value.firstName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Nama Belakang",
      "height": "62px"
    },
    model: {
      value: _vm.value.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "lastName", $$v);
      },
      expression: "value.lastName"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.$v.value.email.$invalid,
      "is-required": "",
      "mb": "80px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Email ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "email",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Email",
      "height": "62px",
      "variant": "filled",
      "disabled": ""
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.oldPassword && _vm.$v.oldPassword.$invalid,
      "mt": "80px",
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Password Lama ")]), _c('c-input-group', [_c('c-input', {
    attrs: {
      "type": _vm.shownPassword.includes('oldPassword') ? "text" : "password",
      "placeholder": "Masukkan Password Lama",
      "h": "62px",
      "tabindex": "1"
    },
    model: {
      value: _vm.oldPassword,
      callback: function callback($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": "translateY(-20%)"
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('oldPassword');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-show-password.svg'),
      "alt": "toggle password visibility"
    }
  })], 1)], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.newPassword && _vm.$v.newPassword.$invalid,
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Password Baru ")]), _c('c-input-group', [_c('c-input', {
    attrs: {
      "type": _vm.shownPassword.includes('newPassword') ? "text" : "password",
      "placeholder": "Masukkan Password Baru",
      "h": "62px",
      "tabindex": "1"
    },
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": "translateY(-20%)"
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('newPassword');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-show-password.svg'),
      "alt": "toggle password visibility"
    }
  })], 1)], 1)], 1), _vm.newPassword && !_vm.$v.newPassword.minLength ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password minimal 8 karakter ")]) : _vm._e(), _vm.newPassword && !_vm.$v.newPassword.hasNumber ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung angka ")]) : _vm._e(), _vm.newPassword && !_vm.$v.newPassword.hasLowercaseLetter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung huruf kecil ")]) : _vm._e(), _vm.newPassword && !_vm.$v.newPassword.hasUppercaseLetter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung huruf kapital ")]) : _vm._e(), _vm.newPassword && !_vm.$v.newPassword.hasSpecialCharacter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung karakter spesial @$!%()*?& ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.newPasswordConfirmation && _vm.$v.newPasswordConfirmation.$invalid,
      "mb": "25px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Konfirmasi Password Baru ")]), _c('c-input-group', [_c('c-input', {
    attrs: {
      "type": _vm.shownPassword.includes('newPasswordConfirmation') ? "text" : "password",
      "placeholder": "Ulangi Password Baru",
      "h": "62px",
      "tabindex": "1"
    },
    model: {
      value: _vm.newPasswordConfirmation,
      callback: function callback($$v) {
        _vm.newPasswordConfirmation = $$v;
      },
      expression: "newPasswordConfirmation"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": "translateY(-20%)"
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('newPasswordConfirmation');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-show-password.svg'),
      "alt": "toggle password visibility"
    }
  })], 1)], 1)], 1), !_vm.$v.newPasswordConfirmation.sameAs ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password baru tidak sama ")]) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "mt": "70px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onOpenConfirm
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Apa kamu yakin ingin mengubah data?",
      "is-open": _vm.isConfirm,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmit
    }
  }), _c('ModalError', {
    attrs: {
      "is-open": _vm.isOpenError,
      "image": require('@/assets/images/illustration-6-raw.png'),
      "title": "Terdapat data yang salah",
      "note": _vm.errorNote,
      "button-text": "Kembali",
      "button-icon": false,
      "button-action": function buttonAction() {
        return _vm.onCloseError();
      }
    },
    on: {
      "close": _vm.onCloseError
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }