import { render, staticRenderFns } from "./form-client.vue?vue&type=template&id=5b1c7db2&scoped=true&"
import script from "./form-client.vue?vue&type=script&lang=js&"
export * from "./form-client.vue?vue&type=script&lang=js&"
import style0 from "./form-client.vue?vue&type=style&index=0&id=5b1c7db2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1c7db2",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CBox: require('@chakra-ui/vue').CBox, CSelect: require('@chakra-ui/vue').CSelect, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CButton: require('@chakra-ui/vue').CButton})
